<template>
    <modal ref="modalAsignarOperador" titulo="Asignar entregas al operador logístico" tamano="modal-xl" no-aceptar adicional="Asignar" @adicional="asignar">
        <div class="row mx-0 align-items-center py-3">
            <div class="col-auto text-general f-18 f-500">
                Entregas programadas para el: {{ fecha | helper-fecha('dddd DD MMMM YYYY') }} {{ hora }}
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12">
                <el-table
                :data="datos"
                header-row-class-name="text-general f-16 f-500"
                style="width: 100%"
                @selection-change="seleccionarPedido"
                >
                    <el-table-column
                    fixed
                    type="selection"
                    width="45"
                    prop="id"
                    value="id"
                    />
                    <el-table-column
                    fixed
                    class-name="text-center"
                    width="110"
                    >
                        <template slot-scope="{ row }">
                            <div class="row mx-0 justify-content-around">
                                <div class="col px-0">
                                    <el-tooltip v-if="row.intentos" placement="bottom" content="Nuevo intento de entrega" effect="light">
                                        <i class="icon-history f-20 text-general mx-2" />
                                    </el-tooltip>
                                </div>
                                <div class="col px-0">
                                    <el-tooltip v-if="row.agotado" placement="bottom" content="Productos faltantes" effect="light">
                                        <i class="icon-package-variant f-20 text-general-red mx-2" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    fixed
                    label="Pedido"
                    width="100"
                    >
                        <template slot-scope="{ row }">
                            <p>
                                {{ row.id }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Leechero"
                    fixed
                    width="120"
                    class-name="text-center"
                    >
                        <template slot-scope="{row}">
                            <p>
                                {{ row.leechero }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="cliente"
                    label="Cliente"
                    class-name="text-center"
                    min-width="180"
                    />
                    <el-table-column
                    prop="cedis"
                    label="Cedis"
                    class-name="text-center"
                    min-width="180"
                    />
                    <el-table-column
                    label="Valor"
                    min-width="100"
                    class-name="text-center"
                    >
                        <template slot-scope="{ row }">
                            <p>
                                {{ separadorNumero(row.valor) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="peso"
                    min-width="125"
                    label="Peso (Kg)"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="volumen"
                    min-width="140"
                    label="Volumen (m3)"
                    class-name="text-center"
                    />
                </el-table>
            </div>
        </div>
    </modal>
</template>

<script>
import Pedidos from "~/services/pedidos/pedidos-admin-envios";
import tablero from '~/services/routing/tablero'
import moment from 'moment'
export default {
    data(){
        return {
            seleccionados: [],
            buscar: '',
            datos: [],
            fecha: '',
            horario: '',
        }
    },
    computed: {

        hora(){
            return this.formatDate(`${this.fecha} ${this.horario}`)
        }
    },
    methods: {
        formatDate: (fecha) => moment(fecha).format('hh:mm a'),
        seleccionarPedido(val){
            this.seleccionados = val;
        },
        async asignar(){
            if (this.seleccionados.length > 0){
                try {
                    let params =  {
                        ids: this.seleccionados,
                    }
                    const {data} = await Pedidos.postAsignar(params)
                    this.notificacion('Mensaje', 'Asignación exitosa!', 'success')
                    this.$emit('consultar');
                    this.$refs.modalAsignarOperador.toggle();
                } catch (error){
                    this.error_catch(error)
                }
            } else {
                this.notificacion('Atención', 'Debe seleccionar al menos un registro', 'warning')
            }
        },
        async dataAsignarOperador(pedidos){
            try {
                let params = { pedidos }
                const { data } = await tablero.dataAsignarOperador(params);
                this.datos = data.pedidos
            } catch (error){
                this.error_catch(error)
            }
        },
        async toggle(pedidos, fecha, horario){
            this.fecha = fecha
            this.horario = horario
            await this.dataAsignarOperador(pedidos)
            this.$refs.modalAsignarOperador.toggle();
        }
    }
}
</script>
